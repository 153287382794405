










































import AppDialog from "@/components/Navigation/AppDialog.vue";
import { WatchLoading } from "@/decorators/Loading";
import { Functions } from "@/firebase";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import {
  Membership,
  CancelSubscription,
  Subscription,
  BaseUser,
  AppUser
} from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { MembershipResponse } from "@sportango/backend";
import { Prop } from "vue-property-decorator";

@Component({
  name: "unsubscribe",
  components: {
    AppDialog
  }
})
export default class Unsubscribe extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  EmitClose
) {
  @Prop({ required: false, default: null })
  selectedUser!: BaseUser | null;

  errorMessage: string | null = null;

  get user(): BaseUser | AppUser | null {
    if (this.selectedUser) {
      return this.selectedUser;
    } else if (this.currentUser) {
      return this.currentUser;
    }
    return null;
  }

  get selectedMembership(): Membership | undefined {
    return this.$store.getters.memberships.find(
      (m) => m.id === this.$route.params.membershipId
    );
  }

  get userMembership(): Membership | undefined {
    if (this.userSubscription) {
      return this.$store.getters.memberships.find((m) =>
        this.userSubscription?.products.find((p) => p.productId === m.id)
      );
    }
    return undefined;
  }

  get userSubscription(): Subscription | undefined {
    return this.$store.getters.subscriptions.find(
      (s) => s.stripeCustomerId === this.user?.stripeCustomerId
    );
  }

  @WatchLoading()
  async cancelSubscription() {
    if (
      this.user &&
      this.user.stripeCustomerId &&
      this.$store.getters.merchantInfo?.merchantId &&
      this.userSubscription
    ) {
      const payload: CancelSubscription = {
        accountId: this.$store.getters.merchantInfo.merchantId,
        customerId: this.user.stripeCustomerId,
        subscriptionId: this.userSubscription.id,
        userId: this.user.uid
      };
      const { data: response } = await httpsCallable<
        CancelSubscription,
        MembershipResponse
      >(
        Functions,
        "cancelSubscription"
      )(payload);
      if (response.isSuccess) {
        this.$store.commit(
          "subscriptions",
          this.$store.getters.subscriptions.filter(
            (s) => s.id !== this.userSubscription?.id
          )
        );
        this.close();
      } else {
        this.errorMessage = response.message || "Something went wrong";
      }
    }
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getAllMemberships");
    await this.$store.dispatch(
      "getUserSubscription",
      this.user?.stripeCustomerId
    );
  }
}
